.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

h1 {
  font-size: calc(40px + 20vmin);
}

label {
  margin-bottom: 20px;
  width: 60%;
  max-width: 300px;
  display: flex;
  justify-content: flex-end;
}

input {
  font-size: calc(10px + 2vmin);
  /* margin-left: 20px; */
  width: 50px;
}

label > :first-child {
  margin-left: 20px;
}

button {
  font-size: calc(10px + 2vmin);
}

label > button {
  font-size: calc(6px + 2vmin);
}